import React from 'react'
import { FormattedMessage } from 'react-intl'
import VendorPageModel from './models/VendorPageModel'
import VendorRequestForm, { VendorRequestFormMobileSubmit } from './VendorRequestForm'
import TranslationProvider from '../../providers/TranslationProvider'
import VendorImageGallery from './VendorImageGallery'
import VendorLocations from './VendorLocations'

export default class VendorPageApp extends React.Component {
  constructor (props) {
    super(props)

    this.model = new VendorPageModel({
      vendorSlug: props.vendorSlug,
      locale: props.locale,
      defaultCountryCode: props.defaultCountryCode,
      defaultPhoneCountry: props.defaultPhoneCountry,
      orderFileUploadEnabled: props.orderFileUploadEnabled,
      howItWorksEnabled: props.howItWorksEnabled,
      vendorInfo: {
        mainName: props.mainName,
        photoURL: props.photoURL,
        carouselURLs: props.carouselURLs,
        serviceAreas: props.service_areas,
        aboutVendor: props.aboutVendor,
        services: props.services,
        locations: props.locations,
        categories: props.categories
      }
    })
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorPageApp mounted')
    }
  }

  render () {
    return (
            <TranslationProvider user_locale={this.model.locale}>
                <div className="vendor__page_wrap d-flex flex-column align-items-center gap-3 gap-md-5 w-100 h-100">
                  {this.model.hasBackUrl && (
                    <div className='back_prev w-100 d-flex justify-content-start'>
                      <a className='back_prev_link' href={this.model.backToSearchUrl}>
                        <FormattedMessage id='vendors.vendors_list' defaultMessage='Vendors list'>
                          {text => text}
                        </FormattedMessage>
                      </a>
                    </div>
                  )}
                  <VendorImageGallery model={this.model} />
                  <div className='vendor__page_block row align-items-start w-100'>
                      <div className="vendor__info col mb-4">
                          <div className="vendor__info_main d-flex gap-3">
                              <div className='vendor__info_image'>
                                  <img src={this.model.vendorInfo.photoURL} alt='Vendor avatar'/>
                              </div>
                              <header className="vendor__info_text mb-3">
                                  <h1>{this.model.vendorInfo.mainName}</h1>
                                  <VendorLocations model={this.model} />
                              </header>
                          </div>
                          <VendorImageGallery isMobile model={this.model} />
                          {this.model.vendorInfo.aboutVendor.length > 0 &&
                              <section id="about" className='about_vendor mt-4'>
                                  <FormattedMessage tagName="h2" id='vendors.about_us_heading'
                                                    defaultMessage='About us'/>
                                  <div className='vendor__info_about mt-4 mb-1'
                                      dangerouslySetInnerHTML={{ __html: this.model.vendorInfo.aboutVendor }}>
                                  </div>
                              </section>
                          }
                          {this.model.vendorInfo.services.length > 0 &&
                              <section id="services" className="vendor__info_details mt-5">
                                  <FormattedMessage className='vendor__info_details_text' tagName="h2"
                                                    id='vendors.services_heading'
                                                    defaultMessage='Services and Prices'/>
                                  <div className='vendor__info_details_list mt-4'
                                        dangerouslySetInnerHTML={{ __html: this.model.vendorInfo.services }}>
                                  </div>
                              </section>
                          }
                          {this.model.howItWorksEnabled && <h2>HiW flag enabled</h2>}
                      </div>
                      <VendorRequestForm model={this.model} />
                      <div className='send_request_fixed w-100'>
                          <div className="actions w-100">
                              <VendorRequestFormMobileSubmit model={this.model}/>
                          </div>
                      </div>
                  </div>
              </div>
            </TranslationProvider>
    )
  }
}
