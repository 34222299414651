import { observable, action, makeObservable } from 'mobx'
import {
  lead_intake_vendors_path,
  lead_intake_success_path
} from '../../../routes.js.erb'

export type FavoriteConnect = 'whatsapp' | 'phone'

interface IOrderInfo {
    client_name: string
    client_countryCode: string
    client_phone: string
    wishes: string
    deadlines: string
    additional: string
    favorite_connect: FavoriteConnect
}

export interface IVendorsLeadIntakeModel {
    currentStep: number
    setCurrentStep: (newStep: number) => void
    intakeOptions: string[]
    selectedIntakeOption: string
    setIntakeOption: (id: number | 'another') => void
    orderInfo: IOrderInfo
    serverPath: string
    locale: string
    locationSlug: string
    categorySlug: string
    setClientName: (name: string) => void
    setClientPhone: (phone: string) => void
    setClientCountryCode: (countryCode: string) => void
    setOrderWishes: (wish: string) => void
    setOrderDeadlines: (deadlines: string) => void
    setOrderAdditional: (additional: string) => void
    setFavoriteConnect: (connectType: FavoriteConnect) => void
    saveLeadIntakeOnServer: () => void
}

class VendorsLeadIntakeModel implements IVendorsLeadIntakeModel {
  currentStep: number
  serverPath: string
  locale: string
  locationSlug: string
  categorySlug: string
  intakeOptions: string[]
  selectedIntakeOption: string
  orderInfo: IOrderInfo

  constructor ({ locale, locationSlug, categorySlug }: {
        locale: string
        locationSlug: string
        categorySlug: string
    }) {
    this.currentStep = 0
    this.intakeOptions =
            ['printing_intake', 'branding_intake', 'special_intake'] // Get from props then

    this.locale = locale
    this.locationSlug = locationSlug
    this.categorySlug = categorySlug
    this.orderInfo = {
      client_name: '',
      client_countryCode: '+1',
      client_phone: '',
      wishes: '',
      deadlines: '',
      additional: '',
      favorite_connect: 'whatsapp'
    }

    this.selectedIntakeOption = this.intakeOptions[0]
    this.serverPath = lead_intake_vendors_path(this.locale, this.locationSlug, this.categorySlug)

    makeObservable(this, {
      currentStep: observable,
      intakeOptions: observable,
      selectedIntakeOption: observable,
      orderInfo: observable,
      setCurrentStep: action,
      setIntakeOption: action,
      setClientName: action,
      setClientPhone: action,
      setClientCountryCode: action,
      setOrderWishes: action,
      setOrderDeadlines: action,
      setOrderAdditional: action,
      setFavoriteConnect: action,
      saveLeadIntakeOnServer: action
    })
  }

  setCurrentStep (newStep: number) {
    this.currentStep = newStep
  }

  setIntakeOption (id: number | 'another') {
    if (id === 'another') {
      this.selectedIntakeOption = id
    } else {
      this.selectedIntakeOption = this.intakeOptions[id]
    }
  }

  setClientName (name: string) {
    this.orderInfo.client_name = name
  }

  setClientCountryCode (countryCode: string) {
    this.orderInfo.client_countryCode = countryCode
  }

  setClientPhone (phone: string) {
    this.orderInfo.client_phone = phone
  }

  setOrderWishes (wish: string) {
    this.orderInfo.wishes = wish
  }

  setOrderDeadlines (deadlines: string) {
    this.orderInfo.deadlines = deadlines
  }

  setOrderAdditional (additional: string) {
    this.orderInfo.additional = additional
  }

  setFavoriteConnect (connectType: FavoriteConnect) {
    this.orderInfo.favorite_connect = connectType
  }

  saveLeadIntakeOnServer () {
    const orderInfoFormData = new FormData()
    orderInfoFormData.append('user_input[intake_category]', this.selectedIntakeOption)
    orderInfoFormData.append('user_input[client_name]', this.orderInfo.client_name)
    orderInfoFormData.append('user_input[client_country_code]', this.orderInfo.client_countryCode)
    orderInfoFormData.append('user_input[client_phone]', this.orderInfo.client_phone)
    orderInfoFormData.append('user_input[wishes]', this.orderInfo.wishes)
    orderInfoFormData.append('user_input[deadlines]', this.orderInfo.deadlines)
    orderInfoFormData.append('user_input[additional]', this.orderInfo.additional)
    orderInfoFormData.append('user_input[favorite_connect]', this.orderInfo.favorite_connect)

    fetch(this.serverPath, {
      method: 'POST',
      body: orderInfoFormData
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(parsedResponse => {
        this.navigateToSuccessPage(parsedResponse.lead_intake.id)
      })
  }

  navigateToSuccessPage (intakeId: string) {
    location.href = lead_intake_success_path(this.locale, intakeId)
  }
}

export default VendorsLeadIntakeModel
