import React, { FC } from 'react'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import { observer } from 'mobx-react'
import { FormattedMessage, useIntl } from 'react-intl'
import PhoneWithCode from '../editor/ReactQuill/PhoneWithCode'

interface IThirdIntakeStep {
    model: IVendorsLeadIntakeModel
}

const ThirdIntakeStep: FC<IThirdIntakeStep> = observer(({ model }) => {
  const intl = useIntl()

  return (
        <div className='third_step w-100 p-3'>
            <div className='w-100 intake_block'>
                <h2 className='mb-3'>
                    <FormattedMessage id='spa.name' />
                    <span className='important'>*</span>
                </h2>
                <input
                    value={model.orderInfo.client_name}
                    onChange={(e) => model.setClientName(e.target.value)}
                    type='text'
                    placeholder={intl.formatMessage({ id: 'intake.forms.name_placeholder' })}
                    name="name_field"
                    id="name_field" />
            </div>
            <div className='w-100 intake_block'>
                <h2 className='mb-3'>
                    <FormattedMessage id='guests.phone' />
                    <span className='important'>*</span>
                </h2>
                <PhoneWithCode
                    country='us'
                    countryCode={model.orderInfo.client_countryCode}
                    countryCodeCallback={model.setClientCountryCode.bind(model)}
                    phone={model.orderInfo.client_phone}
                    phoneCallback={model.setClientPhone.bind(model)}
                    placeholder={intl.formatMessage({ id: 'intake.forms.phone_placeholder' })}
                    readOnly={false}
                />
            </div>
            <div className='w-100 intake_block'>
                <h2 className='mb-3'>
                    <FormattedMessage id='intake.forms.preffered_communication' />
                </h2>
                <div className='mb-3 d-flex flex-wrap-none align-items-center gap-3'>
                    <input type='radio' checked={model.orderInfo.favorite_connect === 'whatsapp'} onChange={() => model.setFavoriteConnect('whatsapp')} id='whatsapp_option' name='favorite_connect' value='whatsapp' />
                    <label className='ms-1' htmlFor='whatsapp_option'>WhatsApp</label>
                </div>
                <div className='mb-3 d-flex flex-wrap-none align-items-center gap-3'>
                    <input type='radio' checked={model.orderInfo.favorite_connect === 'phone'} onChange={() => model.setFavoriteConnect('phone')} id='call_option' name='favorite_connect' value='call' />
                    <label className='ms-1' htmlFor='call_option'>
                        <FormattedMessage id='call' />
                    </label>
                </div>
            </div>
            <div className='actions intake_block mt-5 w-100 d-flex justify-content-center'>
                <button onClick={() => model.saveLeadIntakeOnServer()} type='button' className='main_submit'>
                    <FormattedMessage id='simple_event.next' />
                </button>
            </div>
        </div>
  )
})

export default ThirdIntakeStep
