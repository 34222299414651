import React, { FC } from 'react'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import { observer } from 'mobx-react'
import { FormattedMessage, useIntl } from 'react-intl'

interface ISecondIntakeStep {
    model: IVendorsLeadIntakeModel
}

const SecondIntakeStep: FC<ISecondIntakeStep> = observer(({ model }) => {
  const intl = useIntl()

  return (
        <div className='second_step w-100 p-3'>
            <div className='w-100 intake_block'>
                <h2 className='mb-3'>
                    <FormattedMessage id='intake.forms.need_title' />
                    <span className='important'>*</span>
                </h2>
                <textarea
                    value={model.orderInfo.wishes}
                    onChange={(e) => model.setOrderWishes(e.target.value)}
                    placeholder={intl.formatMessage({ id: 'intake.forms.need_placeholder' })}
                    name="wishes_field"
                    id="wishes_field" />
            </div>
            <div className='w-100 intake_block'>
                <h2 className='mb-3'>
                    <FormattedMessage id='intake.forms.deadline_title' />
                </h2>
                <input
                    value={model.orderInfo.deadlines}
                    onChange={(e) => model.setOrderDeadlines(e.target.value)}
                    type='text'
                    placeholder={intl.formatMessage({ id: 'intake.forms.deadline_placeholder' })}
                    name="deadlines_field"
                    id="deadlines_field" />
            </div>
            <div className='w-100 intake_block'>
                <h2 className='mb-3'>
                    <FormattedMessage id='intake.forms.additional_title' />
                </h2>
                <input
                    value={model.orderInfo.additional}
                    onChange={(e) => model.setOrderAdditional(e.target.value)}
                    type='text'
                    placeholder={intl.formatMessage({ id: 'intake.forms.additional_placeholder' })}
                    name="additional_field"
                    id="additional_field" />
            </div>
            <div className='actions intake_block mt-5 w-100 d-flex justify-content-center'>
                <button onClick={() => model.setCurrentStep(2)} type='button' className='black_button'>
                    <FormattedMessage id='simple_event.next' />
                </button>
            </div>
        </div>
  )
})

export default SecondIntakeStep
