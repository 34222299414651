import React, { FC } from 'react'
import { IVendorsLeadIntakeModel } from './model/VendorsLeadIntakeModel'
import LandingImg from '../../../assets/images/vendors/lead_intake/LandingImg.jpg'
import Stars from '../../../assets/images/vendors/lead_intake/Stars.png'
import Mark from '../../../assets/images/vendors/lead_intake/Mark.png'
import People from '../../../assets/images/vendors/lead_intake/People.png'
import { observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'

interface IFirstIntakeStep {
    model: IVendorsLeadIntakeModel
}

const FirstIntakeStep: FC<IFirstIntakeStep> = observer(({ model }) => (
    <div className='w-100 first_step'>
        <div className='main_block d-flex gap-5'>
            <div className='main_info'>
                <FormattedMessage id='intake.title'>
                    {text => <h1 className='main_heading_bg'>{text}</h1>}
                </FormattedMessage>
                <div className='main_subheading'>
                    <FormattedMessage id='intake.desc' values={{ location: <FormattedMessage id={`locations.short_form.${model.locationSlug}`} /> }}>
                        {text => <h3>{text}</h3>}
                    </FormattedMessage>
                </div>
                <form>
                    <FormattedMessage id='intake.label'>
                        {text => <h6 className='mb-3'>{text}</h6>}
                    </FormattedMessage>
                    {model.intakeOptions.map((option, index) => (
                        <div key={option} className='mb-3 d-flex flex-wrap-none'>
                            <input type='radio' id={option} name='intake_select' value={index} onChange={(e) => model.setIntakeOption(e.target.value as number | 'another')} />
                            <label className='ms-1' htmlFor={option}>
                                <FormattedMessage id={`intake.keys.${option}`} values={{
                                  title: <strong>
                                    <FormattedMessage id={`intake.keys.${option}_title`} />
                                  </strong>
                                }}>
                                </FormattedMessage>
                            </label>
                        </div>
                    ))}
                    <div className='d-flex flex-wrap-none'>
                        <input type='radio' id='another' name='intake_select' value='another' />
                        <label className='ms-2' htmlFor='another'>
                            <FormattedMessage tagName='strong' id='another' />
                        </label>
                    </div>
                </form>
                <div className='actions d-flex align-items-center justify-content-end gap-3'>
                    <FormattedMessage tagName='h6' id='intake.without_sign_up' />
                    <button
                      onClick={() => model.setCurrentStep(1)}
                      className='black_button'
                      type='button'>
                        <FormattedMessage id='simple_event.next' />
                    </button>
                </div>
            </div>
            <div className="main_img">
                <img className='w-100 h-auto' src={LandingImg} alt='Typography' />
            </div>
        </div>
        <div className="benefits_block d-flex justify-content-center align-items-center flex-wrap gap-5">
            <div className="benefit_block d-flex align-items-center gap-1">
                <div className="benefit_info">
                    <h2>4.9+</h2>
                    <FormattedMessage tagName='sub' id='intake.rating'/>
                </div>
                <img src={Stars} alt="Rating" />
            </div>
            <div className="benefit_block d-flex align-items-center gap-1">
                <div className="benefit_info">
                    <h2>856+</h2>
                    <FormattedMessage tagName='sub' id='intake.trusted'/>
                </div>
                <img src={Mark} alt="Rating" />
            </div>
            <div className="benefit_block d-flex align-items-center gap-1">
                <div className="benefit_info">
                    <h2>23 745</h2>
                    <FormattedMessage tagName='sub' id='intake.used_by'/>
                </div>
                <img src={People} alt="Rating" />
            </div>
        </div>
        <div className="info_steps simple_info_block">
            <FormattedMessage id='intake.work_steps.title'>
                {text => <h2><u>{text}</u></h2>}
            </FormattedMessage>
            <div className='d-flex mt-5 flex-column gap-3 align-items-center align-items-md-start flex-md-row walkthrough'>
                <div className='info_step'>
                <div className='d-flex align-items-center justify-content-start mb-3'>
                    <FormattedMessage tagName='h2' id='intake.work_steps.form.title' />
                </div>
                <FormattedMessage tagName='p' id='intake.work_steps.form.desc' />
                </div>
                <div className='next_arrow for_brand_color_line d-none d-md-block mt-3'></div>
                <div className='next_arrow for_brand_color_line mobile d-block d-md-none'></div>
                <div className='info_step'>
                <div className='d-flex align-items-center justify-content-start mb-3'>
                    <FormattedMessage tagName='h2' id='intake.work_steps.request.title' />
                </div>
                <FormattedMessage tagName='p' id='intake.work_steps.request.desc' />
                </div>
                <div className='next_arrow for_brand_color_line d-none d-md-block mt-3'></div>
                <div className='next_arrow for_brand_color_line mobile d-block d-md-none'></div>
                <div className='info_step'>
                <div className='d-flex align-items-center justify-content-start mb-3'>
                    <FormattedMessage tagName='h2' id='intake.work_steps.deal.title' />
                </div>
                <FormattedMessage tagName='p' id='intake.work_steps.deal.desc' />
                </div>
            </div>
        </div>
        <div className="services_banner block">
            <div className="services_banner_content w-100">
                <FormattedMessage tagName='h2' id='intake.banner.title' />
                <div className="black_button">
                    <FormattedMessage id='intake.banner.see_list' />
                </div>
            </div>
        </div>
    </div>
))

export default FirstIntakeStep
