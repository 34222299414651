import React, { Component } from 'react'
import VendorsLeadIntakeModel from './model/VendorsLeadIntakeModel'
import IntakeSteps from './IntakeSteps'
import TranslationProvider from '../providers/TranslationProvider'

// interface VendorsLeadIntakeAppProps{}

export default class VendorsLeadIntakeApp extends Component {
  model: VendorsLeadIntakeModel

  constructor (props: any) {
    super(props)
    this.model = new VendorsLeadIntakeModel({
      locale: props.locale,
      locationSlug: props.locationSlug,
      categorySlug: props.categorySlug
    })
  }

  componentDidMount () {
    if (ahoy) {
      ahoy.track('VendorsLeadIntakeApp mounted')
    }
  }

  render () {
    return (
        <div className='lead_intake_main d-flex justify-content-center w-100'>
            <TranslationProvider user_locale={this.model.locale}>
              <IntakeSteps model={this.model}/>
            </TranslationProvider>
        </div>
    )
  }
}
